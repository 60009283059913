export default class Navigation {
    constructor() {
        // Set elements
        this.el = {
            overlay: document.querySelector('.nav-overlay'),
            nav: document.querySelector('nav'),
        };

        // Add listeners
        this.addListeners();

    }

    addListeners() {
        let _this = this;
        document.querySelector('.nav-toggle').addEventListener('click', () => {
            this.toggle(true);
        });

        let navLinks = document.querySelectorAll('nav a');

        [].forEach.call(navLinks, function (link) {
            link.addEventListener('click', function (e) {
                // Toggle menu
                if(this.hash) {
                    if (
                        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
                        && 
                        location.hostname == this.hostname
                    ) {
                        // Figure out element to scroll to
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        // Does a scroll target exist?
                        if (target.length) {
                            // Only prevent default if animation is actually gonna happen
                            event.preventDefault();
                            $('html, body').animate({
                                scrollTop: target.offset().top
                            }, 1000, function() {
                            // Callback after animation
                        });
                        }
                    } 
                }
                if (_this.el.nav.classList.contains('open')) {
                    _this.toggle(false);
                }
                // Toggle classes
                link.classList.remove('current_page_item');
                this.classList.add('current_page_item');
            });
        });
    }

    toggle() {
        let anim = TweenLite.to(this.el.overlay.querySelector('span'), 0.4, {
            z: 0,
        });
        if (this.el.nav.classList.contains('open')) {
            document.querySelector('html').style = '';
            this.el.nav.classList.remove('open');
            anim.reverse();
            return;
        }
        document.querySelector('html').style = 'overflow: hidden';
        this.el.nav.classList.add('open');
    }
}