import config from '@/config';
import '../scss/main.scss';

// ANIMATION LIBRARY
// https://www.npmjs.com/package/gsap
import TweenLite from 'gsap/TweenLite';
import navigation from './components/nav.js';
import sliders from './components/sliders.js';
import analytics from './components/analytics.js';

// INIT NAVIGATION
new navigation();

// IF USING SLICK ENABLE THIS LINE.
// require('script-loader!../../node_modules/slick-carousel/slick/slick');

$(document).ready(function(e) {
	$('#mededeling').on('click touchend', function (e) {
        $(this).parent().addClass('hidden');
    });
});